@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

//meine Einstellungen
//$orange: #facd03;
//$orange: #FFD300;
//$orange: rgb(255,211,0);
$orange: #ffcf44;
$grid-columns: 8;
$body-background: rgb(177,183,93);
$titlebar-background: rgb(177,183,93);
//$accordion-background: rgb(241,219,108);
$accordion-item-color: #fff;
$accordion-content-color: #4f4f4e;
$accordion-item-margin-bottom: 1rem;
$accordion-background: $white;
$accordion-item-padding: 0.8rem 1rem;
$button-background: #ee7100;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$body-font-color: #4f4f4e;
$menu-item-color: #4f4f4e;
$form-label-color: #4f4f4e;
$input-color: #4f4f4e;
$link-color: #ee7100;


#footer li{
    @include breakpoint(medium){
    list-style-type: disc;
    margin: 0 10px;
    padding-left: 0;
    padding-right: 0;
    }
}
#footer li a{
    @include breakpoint(medium){
    padding-left: 0;
    padding-right: 0;
    }
}
#footer li:first-of-type{
    list-style-type: none;
}
#fortbildungen ul{
    list-style-type: none;
    font-style: italic;
    margin-left: 0px;
}
.fettkursiv{
    font-weight: bold;
    font-style: italic;
}
.fett{
    font-weight: bold;
}
.white{
    color: white;
}
.ausfall{
    color: blue;
}
.kursiv{
    font-style: italic;
}
#kontaktformular input, #kontaktformular textarea{
    border-radius: 10px;
    margin-top: 10px;
}
#kontaktformular .button{
    border-radius: 10px;
    float: right;
    width: 50%;
    margin-top: 20px;
    font-style: italic;
    font-weight: bold;
}

#container{
    padding-bottom: 32px;
}
#beckenbodenlogo{
    width: 30%;
    height: auto;
    margin-left: 35%;
    margin-top: 20px;
    margin-bottom: 20px;
}
body, html {
        min-height:101%;
  }
.sub p{
    margin-bottom: -0.1rem;
}
.sub{
    margin-bottom: 1rem;
}
.link a, .link a:hover, .link2 a, .link2 a:hover{
    color: $link-color;
    font-weight: bold;
}
.link {
    margin-top: -1.25rem;
}
.mailto a, .mailto a:hover{
    color: $link-color;
    font-weight: bold;
}
#sabboeff{
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1.0rem;
    @include breakpoint(medium){
        margin-bottom: 30px;
    }
}
#sabine{
    /*background: url(../img/Sabine_Portrait.jpg) no-repeat;
    background-size: contain;
    background-position: bottom;
    background-origin: padding-box;
    padding-left: 10%;*/
    position: relative;
}
.sabine{
    position: absolute !important;
    bottom: 0;
    left: 10%;
}
.sabinekontakt{
    /*position: absolute !important;
    bottom: 22%;*/
    //padding-left: 35%;
    margin-left: 40%;
    margin-right: 10%;
    margin-top: 360px;
    width: 50%;
}
.adresse{
    position: absolute !important;
    bottom: 10px;
    left: 10%;
}
.adresse p{
    padding-left: 40px !important;
}
#logo{
    padding-left: 10%;
    @include breakpoint(medium){
        padding-right: 15%;
    }
    width: 100%;
    @include breakpoint(small only){
        //@include horizontal-center;
        width: 100%;
        padding-top: 20px;
        margin-bottom: -20px;
    }
}

#head{
    padding-left: 10px;
    @include breakpoint(small){
        h1  {
            font-size: 1rem;
        }  
    }
    @include breakpoint(medium){
        h1  {
            font-size: 1.5rem ;
        }  
    }
}
#inner{
    margin-top: 30px;
    @include breakpoint(medium){
    //padding-left: 20px;
    }
    padding-left: 10px;
}
#outer{
    @include breakpoint(medium) {
    padding-top: 20px;
    border-top: 50px solid rgb(231,236,147);
    }
    background-color: white;
    @include breakpoint(small) {
        padding-top: 20px;
    }
}
.inner-text{
    @include breakpoint(medium) {
        margin-top: 30px;
    }
}
#content{
    @include breakpoint(medium) {
        padding-top: 30px;
        }
    //margin-right: 0px;
    width: 100%;
}
#content h1{
    font-size: 1.5em;
}
#content h2{
    font-size: 1.5em;
    font-style: italic;
}
#example-menu{
    height: 80px;
    @include breakpoint(medium) {
    margin-left: -20px;
    //margin-right: -60px;
    //background: url("../img/Menuebalken_Sabine.jpg") no-repeat;
    //background-size: contain;
    //background-position: top;
    //margin-top: -10%;
    width: 105%;
    }
    @include breakpoint(1090px){
        background: url("../img/Menuebalken_Sabine.jpg") no-repeat !important;
        background-size: contain;
    }
}
#example-menu-small{
    height: 100px;
    margin-top: 20px;
    width: 104%;
}

#example-menu, #example-menu *, #example-menu-small, #example-menu-small *{
    //background-color: rgb(241,219,108);
   // background-color: #f0c937;
    @include breakpoint(1090px){
        background: none;
    }
    background-color: $orange;
    
    /*background: url("../img/Menuebalken_Sabine.jpg") no-repeat;
    background-size: contain;
    background-position: top;*/
}
#example-menu a, #example-menu-small a{
    color: $menu-item-color;
    font-size: 1.05rem;
}
#example-menu li.is-active a, #example-menu-small li.is-active a{
    color: white;
    font-weight: bold;
}
#example-menu li{
    @include breakpoint(1090px){
            padding-left: 20px;

    }
}
#example-menu li:first-of-type{
    padding-left: 0;
}
#footer ul{
    @include breakpoint(medium){
        @include horizontal-center;
        display: flex;
    }
    @include horizontal-center;
    display: block;
    
}
#footer{
    background-color: rgb(231,236,147);
    height: 50px;
    width: 100%;
    @include breakpoint(small only) {
    height: 120px;
    //width: 100%;
    //margin-left: 20px;
    }
}
#footer a{
    color: #333;
}

.accordion .accordion-item a{
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    background-color: #bbb;
    padding-top: 0.5rem;
    height: 35px;
}
.accordion .accordion-item a:hover{
    color: white;
    background-color: $orange;
}
.accordion .accordion-item.is-active a{
    color: white;
    background-color: $orange;
}
.accordion .accordion-item{
    margin-bottom: $accordion-item-margin-bottom;
    //height: 60px;
}
.accordion{
    margin-top: 32px;
}
@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

